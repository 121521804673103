import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, FullTicket, LoadingIcon } from "src/components";
import { history, useMyLinkStore, useCouponStore } from "src/stores";
import { GetToken } from "src/utils/token";

export const CouponDetail = (props: any) => {
  const { location } = props;
  const from = (location.state && location.state.from) || null;
  const coupon = (location.state && location.state.coupon) || null;

  const { t } = useTranslation();
  const [data, setData] = useState({});
  const [myLinkPoint, setMyLinkPoint] = useState(-1);

  const { getMezzofyCoupon } = useCouponStore();

  const [isLoading, setIsLoading] = useState(true);

  const { getMyLinkUserPoints } = useMyLinkStore();

  const toPage = () => {
    switch (from) {
      case "myCoupon":
        return "/my-coupons";
      default:
        return "/coupons-home";
    }
  };

  useEffect(() => {
    if (!coupon) return history.push("/coupons-home");
    if (!coupon.type) {
      setData(coupon);
      setIsLoading(false);
    }
    if (coupon.type && coupon.type === "mezzofy" && coupon.couponId) {
      getMezzofyCoupon(coupon.couponId).then((result: any) => {
        setIsLoading(false);
        if (!result) return;
        let { available_outlets = [] } = result;
        let newCouponData: any = {
          ...coupon,
          availableOutlets: available_outlets, // update available outlets from coupon mezzofy
        };
        result && setData(newCouponData);
      });
    }
  }, [coupon, setData, getMezzofyCoupon, setIsLoading]);

  // call my link
  useEffect(() => {
    const initMyLinkData = async () => {
      const token = GetToken("my-link-token");
      let myLinkPoint: any;
      if (token) {
        let result: any = await getMyLinkUserPoints(token);
        myLinkPoint = result.userPointAvailable;
        setMyLinkPoint(myLinkPoint);
      }
    };
    initMyLinkData();
  });

  return (
    <Container
      from="couponDetail"
      toPage={toPage()}
      title={`${t("coupon")}${t("detail")}`}
      showExchange={true}
    >
      <div className="px-5 py-5">
        {isLoading && (
          <div className="flex justify-center pt-5">
            <LoadingIcon />
          </div>
        )}
        {!isLoading && data && (
          <FullTicket data={data} from={from} myLinkPoint={myLinkPoint} />
        )}
      </div>
    </Container>
  );
};
