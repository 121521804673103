import { t } from "i18next";
import React from "react";
import { LoadingIcon } from "src/components";

export const BottomModalContent = ({
  exchangePoint,
  topUpAmount,
  loading,
  handleExchange,
  setOpen,
  name,
}: any) => {
  return (
    <div className="p-5 flex flex-col items-center">
      <h5 className="mb-5"> {t("confirmExchange")}</h5>

      <div className="flex-row w-[90%]">
        <p className="flex-1">
          {name} {t("points")}
        </p>
        <p className="primary ">-{exchangePoint}</p>
      </div>

      <div className="flex-row w-[90%] mt-[15px]">
        <p className="flex-1">AT {t("points")}</p>
        <p className="text-[#60BE38]">+{topUpAmount}</p>
      </div>

      <div className="flex-col justify-center w-full mt-40 items-center">
        {loading ? (
          <div>
            <LoadingIcon />
          </div>
        ) : (
          <div className="w-full">
            <button
              className="primary w-full py-3"
              onClick={() => handleExchange()}
            >
              {t("confirmExchange")}
            </button>

            <button
              className="primary w-full mt-5 p-[2px]"
              onClick={() => {
                setOpen(false);
              }}
            >
              <div className="flex flex-row items-center h-full w-full bg-white rounded-3xl px-[20px] py-3 justify-center">
                <p className="primary">{t("backUp")}</p>
              </div>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
