import { t } from "i18next";
import React from "react";

const labelClass = "block text-[10px] font-bold text-[#707070]";
const inputClass =
  "mt-2 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-primary focus:ring-primary block w-full rounded-md sm:text-sm focus:ring-1 disabled:opacity-75 ";

export const AtPointBalance = (props: any) => {
  const { balance, topupAtpAmount, setTopupAtpAmount } = props;

  const handleInputChange = (e: any) => {
    setTopupAtpAmount(e.target.value);
  };

  return (
    <div className="bg-white p-[20px] drop-shadow-md">
      <h5>{t("myPoints")}</h5>
      <div className="py-[10px] flex flex-row">
        <div className="flex flex-row items-center">
          <div className="w-[25px] mr-[10px]">
            <img src={"/assets/AT_logo.png"} alt={"at-logo"} />
          </div>
          <div>
            <span className="text-[18px] text-black mr-[5px]">
              {balance ? balance : 0}
            </span>
            <span className="text-[12px] text-black">AT</span>
          </div>
        </div>
      </div>

      {/* Top Up Amount */}
      <label className="block mt-[10px]">
        <span className={labelClass}>{t("topupAmount")}</span>
        <input
          type="number"
          name="amount"
          className={inputClass}
          placeholder="0"
          value={topupAtpAmount}
          onChange={handleInputChange}
          disabled
        />
      </label>

      {/* Exchange Rate */}
      <h5 className="text-[#707070] text-[10px] flex justify-center w-full pt-[15px]">
        {t("exchangeRate")}
      </h5>
    </div>
  );
};
