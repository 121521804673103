import axios from "axios";
import * as Configs from "../config";
import { generalError } from ".";
import {
  errorHandling,
  isResultSuccess,
  refreshNewToken,
  setHeader,
} from "./api";

export class ExchangeStore {
  async getExchangeRate() {
    try {
      const url = `${Configs.apiUrl}/exchange/rate`;
      const result: any = await axios.get(url, setHeader());
      if (!isResultSuccess(result)) return generalError;
      refreshNewToken(result);

      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async exchangeAtpToAT(param: any) {
    try {
      const url = `${Configs.apiUrl}/exchange/atp`;
      const result: any = await axios.post(url, param, setHeader());
      if (!isResultSuccess(result)) return generalError;
      refreshNewToken(result);

      return result["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }
}

export const STORE_EXCHANGE = "exchangeStore";
