import { t } from "i18next";
import React, { useEffect } from "react";
import { LoadingIcon } from "src/components";
import { GetToken } from "src/utils/token";

export const MyPoint = (props: any) => {
  const { list, user, myLinkLoading, atpLoading } = props;
  useEffect(() => {}, [user]);

  const hsaMyLink = () => {
    const myLinkToken = GetToken("my-link-token");
    return myLinkToken;
  };

  const handleLoading = (type: any, value: any) => {
    if (type === "AT") return value;

    switch (type) {
      case "AT":
        return value;
      case "ATP":
        return atpLoading ? <LoadingIcon size={20} /> : value;

      case "MyLink":
        return myLinkLoading ? <LoadingIcon size={20} /> : value;
    }
  };

  return (
    <div className="bg-white drop-shadow-md pb-5 px-5 pt-5 relative">
      <p className="text-pink font-semibold pb-3">{t("myPoints")}</p>

      {list.map((item: any, index: number) => {
        const { type, unit, img, name, paramName } = item;

        if (type === "MyLink" && !hsaMyLink()) return null;
        return (
          <div className="px-3 py-3 flex flex-row" key={index}>
            <div className="w-1/3 flex flex-row items-center">
              <div className="w-4">
                <img src={img} alt={name} />
              </div>
              <div className="w-2/3 font-semibold ml-[10px]">{name}</div>
            </div>

            <div className="w-2/3 font-semibold text-right flex-row justify-end items-end">
              {handleLoading(type, user[`${paramName}`])}
              <span className="ml-2">{unit}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};
