import React from "react";
import { t } from "i18next";

export const AssetsDetails = ({ atPoint, targetPoint, name, logo }: any) => {
  return (
    <div className="bg-white  border-[1px] w-full px-[30px] py-[20px]">
      <div>
        <h5 className="text-base font-semibold tracking-wider text-[#4269AB]">
          {t("from")} {t("atpTitle")} {t("points")}
        </h5>
        <div className="flex-row items-center mt-[10px]">
          <img className="w-[29px] mr-[15px]" src={logo} alt="mylink-logo" />
          <p className="font-[600] text-[14px]">{targetPoint}</p>
          <p className="font-normal text-[12px] ml-2">{name}</p>
        </div>
      </div>

      <div className="mt-[15px]">
        <h5 className="text-base font-semibold">
          {t("to")} AT {t("points")}
        </h5>
        <div className="flex-row items-center mt-[10px]">
          <img
            className="w-[29px] h-auto mr-[15px]"
            src="/assets/AT_logo.jpg"
            alt="at-logo"
          />
          <p className="font-[600] text-[14px]">{atPoint}</p>
          <p className="font-normal text-[12px] ml-2">AT</p>
        </div>
      </div>
    </div>
  );
};
