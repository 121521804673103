import axios from "axios";
import * as Configs from "../config";
import { generalError } from ".";
import {
  errorHandling,
  isResultSuccess,
  refreshNewToken,
  setHeader,
} from "./api";

export class MemberStore {
  async getUserMembershipInfo() {
    const url = `${Configs.apiUrl}/membership/get-user`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!isResultSuccess(result)) return generalError;
      refreshNewToken(result);
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async updateUserInfo(payload: any) {
    const url = `${Configs.apiUrl}/user`;
    try {
      const result: any = await axios.put(url, payload, setHeader());
      if (!isResultSuccess(result)) return generalError;
      refreshNewToken(result);
      return isResultSuccess(result);
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getNftCollections() {
    const url = `${Configs.apiUrl}/user/nft-collections`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!isResultSuccess(result)) return generalError;
      refreshNewToken(result);
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getAtpBalance() {
    const url = `${Configs.apiUrl}/atp/balance`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!isResultSuccess(result)) return generalError;
      refreshNewToken(result);
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }
}

export const STORE_MEMBER = "memberStore";
