import { t } from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import {
  Container,
  openNotificationWithIcon,
  BottomModal,
} from "src/components";
import { history, useMyLinkStore, useMemberStore } from "src/stores";
import { GetToken } from "src/utils/token";
import { AssetsDetails } from "./assetsDetails";
import { BottomModalContent } from "./bottomModalContent";

export const MyLinkExchange = (props: any) => {
  const { getMyLinkUserPoints, getPointLevel, exchange } = useMyLinkStore();
  const { getUserMembershipInfo } = useMemberStore();
  const [atPoint, setAtPoint] = useState(0);
  const [myLinkPoint, setMyLinkPoint] = useState(0);
  const [currentPointLevels, setCurrentPointLevels] = useState([]);
  const [currentPointLevel, setCurrentPointLevel] = useState<any>({});
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // get the points level
  const initMyLinkToken = useCallback(async () => {
    const token = GetToken("my-link-token");
    let myLinkPoint: any;
    if (token) {
      let result: any = await getMyLinkUserPoints(token);
      myLinkPoint = result.userPointAvailable;
      setMyLinkPoint(myLinkPoint);
    }

    const pointLevel = await getPointLevel();
    setCurrentPointLevels(pointLevel);
  }, [getMyLinkUserPoints, getPointLevel]);

  useEffect(() => {
    initMyLinkToken();

    getUserMembershipInfo().then((member) => {
      const { at_points } = member;

      if (at_points) setAtPoint(at_points);
    });
  }, [initMyLinkToken, getUserMembershipInfo]);

  const handleExchange = async () => {
    try {
      setLoading(true);
      await exchange(currentPointLevel.pointLevelCode);
      setLoading(false);
      initMyLinkToken();
      openNotificationWithIcon(
        "info",
        t("messages.congraz"),
        t("exchangeSuccess")
      );
      setOpen(false);
      history.push("point-exchange");
    } catch (e) {
      openNotificationWithIcon(
        "warning",
        t("messages.warning"),
        t("exchange.error.exchangedFailed")
      );
    }
  };

  return (
    <Container title={`${t("exchangeTitle")}`} toPage="/point-exchange">
      <div className="flex flex-col">
        <AssetsDetails
          logo={"/assets/mylink.webp"}
          atPoint={atPoint}
          targetPoint={myLinkPoint}
          name={"MYLINK"}
        />

        <div className="grid row-auto	grid-cols-2	 md:grid-cols-3 gap-2 p-2">
          {currentPointLevels &&
            currentPointLevels.length > 0 &&
            currentPointLevels.map((pointLevel, index) => {
              const { pointLevelCode, pointLevelValue } = pointLevel;

              const active =
                currentPointLevel.pointLevelCode === pointLevelCode
                  ? "primary"
                  : "";

              return (
                <div
                  key={index}
                  className={`bg-white  rounded-2xl border-[1px] w-[179px] ${active} p-[2px]`}
                  // onClick={() => handleExchange(pointLevelCode)}
                  onClick={() => setCurrentPointLevel(pointLevel)}
                >
                  <div className="flex flex-row items-center h-full w-full bg-white rounded-xl p-2">
                    <div>
                      <img
                        className="w-[40px] mr-2 rounded-2xl"
                        src="/assets/mylink.webp"
                        alt="mylink-logo"
                      />
                    </div>
                    <div className="flex flex-col flex-1 mt-2">
                      <h5 className="font-semibold text-[20px] text-black tracking-wider leading-3">
                        {pointLevelValue}
                      </h5>
                      <div className="flex flex-row">
                        <h5 className="font-normal text-[10px]	text-black flex-1 tracking-wider">
                          {pointLevelValue / 100} AT
                        </h5>
                        <h5 className="font-normal text-[12px]	text-black flex-1">
                          MYLINK
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>

        <p className="self-center absolute bottom-24 font-bold">
          {t("exchangeRateTxt")} 1 AT : 100 MYLINK
        </p>

        <button
          className="primary absolute bottom-10 h-10 w-[90%] left-5 right-5"
          onClick={() => {
            if (!currentPointLevel.pointLevelCode) {
              openNotificationWithIcon(
                "warning",
                t("messages.warning"),
                t("messages.mylinkPointInsufficient")
              );
              return;
            }
            setOpen(true);
          }}
        >
          {t("exchangeNow")}
        </button>
      </div>

      <BottomModal setOpen={setOpen} open={open}>
        <BottomModalContent
          exchangePoint={currentPointLevel.pointLevelValue}
          topUpAmount={currentPointLevel.pointLevelValue / 100}
          loading={loading}
          handleExchange={handleExchange}
          setOpen={setOpen}
          name={"MYLINK"}
        />
      </BottomModal>
    </Container>
  );
};
