import axios from "axios";
import * as Configs from "../config";
import { generalError } from ".";
import {
  errorHandling,
  isResultSuccess,
  refreshNewToken,
  setHeader,
} from "./api";
import { GetToken } from "src/utils/token";

export class MyLinkStore {
  async loginByMyLinkToken(payload: any) {
    try {
      const url = `${Configs.apiUrl}/my-link/login`;
      const result: any = await axios.post(url, payload);
      if (!isResultSuccess(result)) return generalError;

      if (result["data"]["data"]["disclaimer"]) {
        const jwt = result["data"]["data"]["jwt"];
        const myLinkUserId = result["data"]["data"]["myLinkUserId"];

        localStorage.setItem("at-token", jwt);
        localStorage.setItem("mylink-user-id", myLinkUserId);
      }

      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async agreeDisclaimer(payload: any) {
    try {
      const url = `${Configs.apiUrl}/my-link/disclaimer`;
      const result: any = await axios.post(url, payload);
      if (!isResultSuccess(result)) return generalError;
      const jwt = result["data"]["data"]["jwt"];

      const myLinkUserId = result["data"]["data"]["myLinkUserId"];
      localStorage.setItem("at-token", jwt);
      localStorage.setItem("mylink-user-id", myLinkUserId);
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getMyLinkUserPoints(token: any) {
    try {
      const url = `${Configs.apiUrl}/my-link/user?token=${token}`;
      const result: any = await axios.get(url, setHeader());
      if (!isResultSuccess(result)) return generalError;
      refreshNewToken(result);
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getPointLevel() {
    try {
      const url = `${Configs.apiUrl}/my-link/point-level`;
      const result: any = await axios.get(url, setHeader());
      if (!isResultSuccess(result)) return generalError;
      refreshNewToken(result);

      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async exchange(pointLevelCode: string) {
    try {
      const url = `${Configs.apiUrl}/exchange/my-link`;
      const myLinkToken = GetToken("my-link-token");

      const result: any = await axios.post(
        url,
        {
          pointLevelCode,
          myLinkToken,
        },
        setHeader()
      );
      if (!isResultSuccess(result)) return generalError;
      refreshNewToken(result);

      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }
}

export const STORE_MYLINK = "myLinkStore";
