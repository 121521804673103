import React from "react";
import { history } from "src/stores";

export const Menu = (props: any) => {
  const {
    from,
    toPageCb,
    title,
    toPage,
    avatar,
    showExchange,
    exchangeContent,
  } = props;

  const backToPage = (toPage: any) => {
    if (typeof toPage === "function") {
      return toPage();
    } else {
      return toPage ? history.push(toPage) : null;
    }
  };

  const handleATTopup = () => {
    switch (from) {
      case "home":
      case "couponDetail":
        toPageCb && toPageCb();
        break;
      default:
        break;
      // return history.push("top-up-atpoints");
    }
  };

  return (
    <div className="fixed h-[120px] top-0 w-screen bg-gradient-to-r from-at-pink to-at-blue flex flex-col justify-end z-50">
      <div
        className={`flex flex-row px-6 pb-1.5 items-center ${
          avatar && "relative"
        }`}
      >
        {avatar && (
          <div className="w-20 h-20 overflow-hidden mr-3 rounded-full mt-5 relative top-10">
            <img
              src={avatar}
              className="w-full h-full object-cover"
              alt="avatar-icon"
            />
          </div>
        )}

        {toPage && (
          <div
            className="w-4 h-4 overflow-hidden mr-3"
            onClick={() => backToPage(toPage)}
          >
            <img
              src="/assets/back.png"
              className="w-full h-full object-cover"
              alt="back-icon"
            />
          </div>
        )}

        {title && (
          <h5
            className={`text-white text-base font-semibold ${
              avatar && "relative top-10"
            }`}
          >
            {title}
          </h5>
        )}

        {showExchange && (
          <div
            onClick={() => handleATTopup()}
            className="absolute bottom-[10px] right-5 bg-white rounded-[10px] p-2 flex flex-row items-center"
          >
            <img
              src="/assets/exchange.gif"
              alt="exchange"
              className="w-[20px] object-fit"
            />
            <p className={`ml-1 rainbow`}>
              {exchangeContent ? exchangeContent : "1 HKD: 1 AT"}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
