import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  BottomModal,
  openNotificationWithIcon,
  LoadingIcon,
  openErrNotification,
} from "src/components";
// import { Exchange } from "./exchange";
import { MyPoint } from "../point-exchange/my-point";
import { GetToken } from "src/utils/token";
import {
  history,
  useMyLinkStore,
  useMemberStore,
  useAuthStore,
} from "src/stores";

import { EXCHANGE_LIST } from "../sample-data/exchange";
import { t } from "i18next";

const exchangeOptions = [
  {
    type: "mylink",
    name: "MyLink Points",
    icon: "/assets/mylink.webp",
    exchangeLink: "/exchange/my-link",
  },
  {
    type: "atp",
    name: t("atpTitle"),
    icon: "/assets/atp_logo.png",
    exchangeLink: "/exchange/at-points",
  },
];

export const PointExchange = (props: any) => {
  const [myLinkPoint, setMyLinkPoint] = useState(0);
  const { t } = useTranslation();
  const { location } = props;
  const { user } = location.state || {};
  const { getMyLinkUserPoints } = useMyLinkStore();
  const { getUserMembershipInfo, getAtpBalance } = useMemberStore();
  const [currentUser, setCurrentUser] = useState(user);
  const [open, setOpen] = useState(false);
  const [currentOption, setCurrentOption] = useState(-1);
  const [atp, setAtp] = useState(0);
  const [myLinkLoading, setMyLinkLoading] = useState(false);
  const [atpLoading, setAtpLoading] = useState(false);
  const { atpAuth } = useAuthStore();

  useEffect(() => {
    const initMyLinkToken = async () => {
      if (!user) {
        const memberInfo = await getUserMembershipInfo();
        setCurrentUser(memberInfo);
      }

      const token = GetToken("my-link-token");
      let myLinkPoint: any;
      if (token) {
        setMyLinkLoading(true);
        let result: any = await getMyLinkUserPoints(token);
        myLinkPoint = result.userPointAvailable;
        setMyLinkPoint(myLinkPoint);
        setMyLinkLoading(false);
      }
    };

    const initATPBalance = () => {
      setAtpLoading(true);
      getAtpBalance().then((result) => {
        const { atp } = result;
        setAtp(atp);
        setAtpLoading(false);
      });
    };

    // login from the home page
    initATPBalance();

    const params: any = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop: any) => searchParams.get(prop),
    });
    const thirdParty = params.from;
    // check the login
    if (thirdParty) {
      switch (thirdParty) {
        case "ATP":
          const { countryCode, phone, nonce, timestamp, appKey, sign } = params;
          atpAuth({
            countryCode,
            phone,
            nonce,
            timestamp,
            appKey,
            sign,
          }).then(() => {
            openNotificationWithIcon(
              "info",
              t("messages.congraz"),
              t("messages.loginSuccess")
            );
            localStorage.setItem("is-atp-user", "yes");
            initATPBalance();
          });
          break;
      }
    }

    initMyLinkToken();
  }, [
    getMyLinkUserPoints,
    user,
    getUserMembershipInfo,
    getAtpBalance,
    atpAuth,
    t,
  ]);

  const handleDirection = () => {
    if (currentOption === -1) {
      openErrNotification(t("exchange.error.emptyExchangeOption"));
      return;
    }
    const link = exchangeOptions[currentOption].exchangeLink;
    history.push(link);
  };

  return (
    <Container toPage={"/wallet"} title={t("myPoints")}>
      {/* <Exchange /> */}
      <MyPoint
        atpLoading={atpLoading}
        myLinkLoading={myLinkLoading}
        list={EXCHANGE_LIST}
        user={{ ...currentUser, myLinkPoint, atp }}
      />

      {(atpLoading || myLinkLoading) && (
        <div className="w-[90%] py-3 absolute bottom-10 mx-5 flex justify-center">
          <LoadingIcon />
        </div>
      )}

      {!atpLoading && !myLinkLoading && (
        <button
          className="primary w-[90%] py-3 absolute bottom-10 mx-5"
          onClick={() => setOpen(true)}
        >
          {t("exchangeTitle")}
        </button>
      )}

      <BottomModal setOpen={setOpen} open={open}>
        <div className="p-5 flex flex-col items-center h-1/4">
          <h5>{t("exchange.selectionTitle")}</h5>

          {exchangeOptions.map((option, index) => {
            const { name, icon, type } = option;

            if (type === "atp" && atp === 0) {
              return null;
            }

            if (type === "mylink" && myLinkPoint === 0) {
              return null;
            }

            return (
              <div
                className="flex-row mt-[10px] w-full"
                onClick={() => setCurrentOption(index)}
                key={name}
              >
                <img
                  className="w-[29px] mr-[15px]"
                  src={icon}
                  alt={`${name}-logo`}
                />
                <p className="font-normal text-[12px] mt-1 ml-2">{name}</p>
                <div className="flex-1" />
                <input
                  type="radio"
                  checked={index === currentOption}
                  onChange={() => setCurrentOption(index)}
                />
              </div>
            );
          })}

          <button
            className="full py-3 text-xs mt-10"
            onClick={() => handleDirection()}
          >
            {t("button.confirm")}
          </button>
        </div>
      </BottomModal>
    </Container>
  );
};
