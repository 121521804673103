import React from "react";
import { t } from "i18next";

const labelClass = "block text-[10px] font-bold text-[#707070]";
const inputClass =
  "mt-2 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-primary focus:ring-primary block w-full rounded-md sm:text-sm focus:ring-1";

const topUpAmountOptions = [
  { amount: "10", currency: "ATP" },
  { amount: "100", currency: "ATP" },
  { amount: "500", currency: "ATP" },
  { amount: "1000", currency: "ATP" },
];

export const ExchangePointScale = ({
  exchangePoint,
  handleInputChange,
  setExchangePoint,
  name,
}: any) => {
  return (
    <div className="bg-white border-[1px] w-full px-[30px] py-[20px] mt-[10px]">
      {/* Top Up Amount */}
      <label className="block">
        <span className={labelClass}>
          {t("exchange.exchangeAmount")} ({name})
        </span>
        <input
          type="number"
          name="amount"
          className={inputClass}
          placeholder="0"
          value={exchangePoint}
          onChange={handleInputChange}
        />
      </label>

      {/* Select Top Up Amount */}
      <div className="flex flex-row whitespace-nowrap scrollbar-hide overflow-x-auto lg:overflow-x-scroll scroll-px-5 snap-x pt-[10px]">
        {topUpAmountOptions.map((option: any, i: any) => {
          return (
            <div key={i} className="max-w-lg">
              <button
                className={`${
                  exchangePoint === option.amount ? "primary" : "secondary"
                } flex overflow-hidden mr-2`}
                onClick={() => setExchangePoint(option.amount)}
              >
                <p
                  className={`flex flex-row py-[5px] text-[10px] ${
                    exchangePoint === option.amount
                      ? "text-white"
                      : "text-at-pink"
                  }`}
                >
                  <span className="pr-1">{option.amount}</span>
                  <span>{option.currency}</span>
                </p>
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};
