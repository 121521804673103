export const EXCHANGE_LIST = [
  {
    img: "/assets/AT_logo.png",
    name: "AT 積分",
    unit: "AT",
    type: "AT",
    paramName: "at_points",
    // num: 350
  },
  {
    img: "/assets/mylink.webp",
    name: "MyLink",
    unit: "積分",
    type: "MyLink",
    paramName: "myLinkPoint",
  },

  {
    name: "聚分俠 ATP",
    img: "/assets/atp_logo.png",
    unit: "ATP",
    type: "ATP",
    paramName: "atp",
  },
];

export const FROM_EXCHANGE = {
  img: "/assets/AT_logo.png",
  name: "AT 積分",
  unit: "AT",
  num: 350,
};

export const REACH_EXCHANGE = {
  img: "https://www.ly.com/favicon.ico",
  name: "同程旅行",
  unit: "PTS",
  num: 300,
};
