import React, { useEffect, useState } from "react";
import { t } from "i18next";

import {
  Container,
  openNotificationWithIcon,
  BottomModal,
} from "src/components";
import { BottomModalContent } from "./bottomModalContent";
import { AssetsDetails } from "./assetsDetails";
import { ExchangePointScale } from "./exchangePointScale";
import { history, useMemberStore, useExchangeStore } from "src/stores";

export const ATPExchange = (props: any) => {
  const { getUserMembershipInfo, getAtpBalance } = useMemberStore();
  const { getExchangeRate, exchangeAtpToAT } = useExchangeStore();
  const [atPoint, setAtPoint] = useState(0);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [atpLoading, setAtpLoading] = useState(false);
  const [exchangePoint, setExchangePoint] = useState("0");
  const [rate, setRate] = useState(0);
  const [atp, setAtp] = useState(0);

  useEffect(() => {
    getUserMembershipInfo().then((member) => {
      const { at_points } = member;
      if (at_points) setAtPoint(at_points);
    });

    getExchangeRate().then((rates) => {
      const { atp_ap } = rates;
      setRate(atp_ap);
    });

    setAtpLoading(true);
    getAtpBalance().then((result) => {
      const { atp } = result;
      setAtp(atp);
      setAtpLoading(false);
    });
  }, [getUserMembershipInfo, getExchangeRate, getAtpBalance, setAtpLoading]);

  const handleExchange = async () => {
    try {
      setLoading(true);
      const result = await exchangeAtpToAT({ atpAmount: exchangePoint });
      const { success } = result;
      setLoading(false);
      if (success)
        openNotificationWithIcon(
          "info",
          t("messages.congraz"),
          t("exchangeSuccess")
        );

      if (!success) {
        openNotificationWithIcon(
          "warning",
          t("messages.warning"),
          t("exchange.error.exchangedFailed")
        );
      }

      setOpen(false);
      history.push("/point-exchange");
    } catch (e) {
      openNotificationWithIcon(
        "warning",
        t("messages.warning"),
        t("exchange.error.exchangedFailed")
      );
    }
  };

  const handleInputChange = (e: any) => {
    const inputValue = e.target.value;

    // Check if the input value is a valid integer
    if (Number.isInteger(Number(inputValue)) || "") {
      // The input value is an integer, so you can proceed with your logic here
      setExchangePoint(inputValue);
    }
  };

  const openConfirmModal = () => {
    let errorMsg = "";
    if (atpLoading) {
      return;
    }

    if (!exchangePoint) errorMsg = t("exchange.error.empty");
    if (parseFloat(exchangePoint) === 0) errorMsg = t("exchange.error.empty");
    // check balance
    if (parseFloat(exchangePoint) > atp)
      errorMsg = t("exchange.error.ATPBalanceInsufficient");

    if (errorMsg) {
      openNotificationWithIcon("error", t("messages.warning"), errorMsg);
      return;
    }

    setOpen(true);
  };

  return (
    <Container title={`${t("exchangeTitle")}`} toPage="/point-exchange">
      <div className="flex flex-col">
        <AssetsDetails
          logo={"/assets/atp_logo.png"}
          atPoint={atPoint}
          targetPoint={atp}
          name={"ATP"}
        />

        {/* exchange inputs */}
        <ExchangePointScale
          exchangePoint={exchangePoint}
          handleInputChange={handleInputChange}
          setExchangePoint={setExchangePoint}
          name={"ATP"}
        />

        {/* exchange rate */}
        <p className="self-center absolute bottom-24 font-bold">
          {t("exchangeRateTxt")} 1 {t("atpTitle")} : {rate} AT
        </p>

        {/* confirm button */}
        <button
          className="primary absolute bottom-10 h-10 w-[90%] left-5 right-5"
          onClick={openConfirmModal}
        >
          {t("exchangeNow")}
        </button>
      </div>

      {/* double confirm messages */}
      <BottomModal setOpen={setOpen} open={open}>
        <BottomModalContent
          exchangePoint={exchangePoint}
          topUpAmount={parseFloat(exchangePoint) * rate}
          loading={loading}
          handleExchange={() => handleExchange()}
          setOpen={setOpen}
          name={t("atpTitle")}
        />
      </BottomModal>
    </Container>
  );
};
